<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div>
        <v-footer
          tile
          flat
          height="80"
          class="font-weight-medium shadow-md"
          :color="$vuetify.theme.dark ? 'dark' : 'white'"
          :dark="$vuetify.theme.dark"
          :inset="true"
        >
          <v-col
            class="text-left py-0"
            cols="12"
          >
            <v-row class="py-0">
              <v-col
                cols="6"
                :sm="2"
                class="py-0"
              >
                <div>
                  <v-select
                    id="matchdaySelect"
                    :items="matchdays"
                    v-model="matchdayFilter"
                    @change="fixtureList()"
                    label="Matchday"
                  />
                </div>
              </v-col>
              <v-col
                cols="6"
                :sm="2"
                class="py-0">
                <div>
                  <v-select
                    id="competitionSelect"
                    :items="competitions"
                    v-model="competitionFilter"
                    @change="fixtureList()"
                    label="Competition"
                  />
                </div>
              </v-col>
              <v-col
                cols="6"
                :sm="2"
                v-if="$vuetify.breakpoint.smAndUp"
                class="py-0">
                <div>
                  <v-select
                    id="competitionSelect"
                    :items="teamList"
                    v-model="selectedTeam"
                    clearable
                    @change="fixtureList()"
                    label="Team"
                  />
                </div>
              </v-col>
              <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
              <v-col
                cols="4"
                :sm="2"
                v-if="$vuetify.breakpoint.smAndUp"
                class="py-0"
              >
                <div>
                  <v-menu
                    ref="fromMenu"
                    v-model="fromMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedDateFrom"
                        label="From"
                        :prepend-icon="$vuetify.breakpoint.smAndDown ? '' : 'mdi-calendar'"
                        readonly
                        multiple
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="dateFrom"
                      no-title
                      scrollable
                      @change="fixtureList()"
                      @input="fromMenu = false"
                      :format="['d/M/yyyy']"
                    >
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col
                cols="4"
                :sm="2"
                v-if="$vuetify.breakpoint.smAndUp"
                class="py-0"
              >
                <div>
                  <v-menu
                    ref="toMenu"
                    v-model="toMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedDateTo"
                        label="To"
                        :prepend-icon="$vuetify.breakpoint.smAndDown ? '' : 'mdi-calendar'"
                        readonly
                        multiple
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="dateTo"
                      no-title
                      scrollable
                      @change="fixtureList()"
                      @input="toMenu = false"
                      :format="['d/M/yyyy']"
                    >
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-footer>
      </div>
      <div v-if="filteredFixtures.length > 0">
        <base-card
          v-for="stage in competitionStages"
          :key="stage"
          class="mt-2"
        >
          <v-card-title v-if="filteredFixtures.length > 0">{{ stage }}</v-card-title>
          <v-card-text  v-if="filteredFixtures.length > 0" :class="$vuetify.breakpoint.xsOnly ? 'pa-1' : ''">
            <v-data-table
              :headers="activeHeaders"
              :items="filteredFixturesForStage(stage)"
              :footer-props="{
                itemsPerPageText: 'Display'
              }"
              class="elevation-1"
              group-by="matchday"
              :custom-sort="customSort"
              mobile-breakpoint="0"
            >
              <template v-slot:group.header="item">
                <td :colspan="headers.length">
                  <div class="header-parent">
                    <div class="inline-block">
                      <div>
                        <div>
                           {{ item.items[0].matchdayLabel }}
                          <v-btn class="positioningElement" />
                        </div>
                      </div>
                    </div>
                    <div class="inline-block">
                      <div class="grouper">
                        <div>
                          <v-btn
                            icon
                            @click="item.toggle"
                          >
                            <v-icon>{{ item.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </template>

              <template v-slot:item.homeLogo="{item}">
                <v-img
                  :src="`/assets/gaa/teams/icons/${item.homeLogo}.WEBP`"
                  :alt="item.homeLogo"
                  contain
                  max-width="30"
                  class="img_border"
                ></v-img>
              </template>
              <template v-slot:item.home="{item}">
                <h6 class="ma-0 font-weight-medium">
                  {{ item.homeTeam }}
                </h6>
              </template>

              <template v-slot:item.date="{item}">
                {{  formatDateUS(item.date) }}
              </template>

              <template v-slot:item.time="{item}">
                {{ matchTimeString(item) }}
              </template>

              <template v-slot:item.venue="{item}">
                {{ $vuetify.breakpoint.xsOnly ? item.venue.substring(item.venue.indexOf(',') + 1) : item.venue }}
              </template>

              <template v-slot:item.away="{item}">
                <h6 class="ma-0 font-weight-medium">
                  {{ item.awayTeam }}
                </h6>
              </template>
              <template v-slot:item.awayLogo="{item}">
                <v-img
                  :src="`/assets/gaa/teams/icons/${item.awayLogo}.WEBP`"
                  :alt="item.awayLogo"
                  contain
                  max-width="30"
                  class="img_border"
                ></v-img>
              </template>
              <template v-slot:item.coverage="{item}">
                <div>
                  <v-img contain :max-width="$vuetify.breakpoint.xsOnly ? 50 : 80" :src="`/assets/tv/logo/${item.coverage}.jpg`" :alt="item.coverage"/>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </base-card>
      </div>
      <div v-else>
        <base-card><v-card-text>No data available</v-card-text></base-card>
      </div>
    </v-col>
  </v-row>
</template>
<script>
  import { mapGetters, mapActions } from "vuex";
  import avatarGroupCard from '@/components/card/AvatarGroupCard'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Table',
    },
    components: {
      avatarGroupCard,
    },
    mounted() {
      this.fetchFixtures(3); //TODO - hardcoded comp
    },
    data () {
      return {
        headers: [
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'homeLogo',
          },
          { text: 'Home', value: 'home' },
          { text: '', value: 'time' },

          { text: 'Away', value: 'away' },
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'awayLogo',
          },
          { text: 'Date', value: 'date' },
          { text: 'Matchday', value: 'matchday' },
          { text: 'Venue', value: 'venue' },
          { text: 'TV', value: 'coverage' },
          { text: '', value: 'data-table-expand' },
        ],
        matchdayFilter: 'All',
        competitionFilter: 'All',
        dateFrom: '',
        dateTo: '',
        fromMenu: false,
        toMenu: false,
        selectedTeam: null,
        filteredFixtures: []
      }
    },
    computed: {
      ...mapGetters(["getFixtures"]),
      teamList(){
        return [...this.getFixtures.map(it => it.homeTeam)]
      },
      activeHeaders () {
        if (this.$vuetify.breakpoint.smAndUp) {
          return this.headers
        } else {
          return [{ text: 'Home', value: 'home', sortable: false },
                  { text: '', value: 'time', sortable: false },
                  { text: 'Away', value: 'away', sortable: false },
                  { text: 'Date', value: 'date', sortable: false },]
        }
      },
      competitionStages(){
        if(this.$route.meta.type == 'fixtures'){
          return  [...this.filteredFixtures
            .filter(fixture => fixture.matchResult === null) // Filter fixtures where matchResult is null
            .reduce((set, fixture) => set.add(fixture.stage), new Set())
          ].sort();
        } else {
          return  [...this.filteredFixtures
            .filter(fixture => fixture.matchResult !== null) // Filter fixtures where matchResult is null
            .reduce((set, fixture) => set.add(fixture.stage), new Set())
          ].sort();
        }
      },
      matchdays(){
        let matchdays = [];
        if(this.$route.meta.type == 'fixtures'){
          matchdays = [
            ...this.getFixtures
              .filter(fixture => fixture.matchResult == null)
              .reduce((set, fixture) => set.add(`Matchday ${fixture.matchday}`), new Set())
          ].sort();
        } else {
          matchdays = [
            ...this.getFixtures
              .filter(fixture => fixture.matchResult !== null)
              .reduce((set, fixture) => set.add(`Matchday ${fixture.matchday}`), new Set())
          ].sort();
        }
        if(matchdays.length>1){
          matchdays.unshift('All');
        } else if(matchdays.length==0){
          matchdays[0] = 'All';
        }else {
          this.matchdayFilter = matchdays[0];
        }
        matchdays.sort((a, b) => {
          if (a === "All") return -1;
          if (b === "All") return 1;

          const aMatchday = a === "All" ? 0 : parseInt(a.split(' ')[1], 10);
          const bMatchday = b === "All" ? 0 : parseInt(b.split(' ')[1], 10);

          return bMatchday - aMatchday;
        });
        return matchdays;
      },
      competitions(){
        let competitions = [];
        if(this.$route.meta.type == 'fixtures'){
          competitions = [
            ...this.getFixtures
              .filter(fixture => fixture.matchResult == null)
              .reduce((set, fixture) => set.add(`${fixture.stage}`), new Set())
          ].sort();
        } else {
          competitions = [
            ...this.getFixtures
              .filter(fixture => fixture.matchResult !== null)
              .reduce((set, fixture) => set.add(`${fixture.stage}`), new Set())
          ].sort();
        }
        if(competitions.length > 1){
          competitions.unshift('All');
        } else if(competitions.length == 0){
          competitions[0] = 'All';
        } else {
          this.competitionFilter = competitions[0];
        }
        return competitions;
      },
      formattedDateTo: {
        get: function() {
          return this.dateTo ? this.formatDate(this.dateTo) : '';
        },
        set: function(newValue) {
          this.dateTo = newValue;
          this.fixtureList();
        }
      },
      formattedDateFrom: {
        get: function() {
          return this.dateFrom ? this.formatDate(this.dateFrom) : '';
        },
        set: function(newValue) {
          this.dateFrom = newValue;
          this.fixtureList();
        }
      }
    },
    methods: {
      ...mapActions([
        "fetchFixtures"
      ]),
      fixtureList(){
        let fixtureList = this.$store.getters['getFixtures'];
        let filteredFixturesList;

        if(this.$route.meta.type == 'fixtures'){
          filteredFixturesList = fixtureList.filter((fixture) => !fixture.matchResult);
        } else {
          filteredFixturesList = fixtureList.filter((fixture) => fixture.matchResult);
        }
        if(!this.matchdayFilter || this.matchdayFilter == 'All'){
          filteredFixturesList = filteredFixturesList;
        } else {
          filteredFixturesList = filteredFixturesList.filter((fixture) => fixture.matchday == this.matchdayFilter.substring(9));
        }

        if(this.dateFrom){
          filteredFixturesList = filteredFixturesList.filter((fixture) => this.compareDates(this.dateFrom, fixture.date) <= 0);
        }
        if(this.dateTo){
          filteredFixturesList = filteredFixturesList.filter((fixture) => this.compareDates(this.dateTo, fixture.date) >= 0);
        }
        if(this.selectedTeam){
          filteredFixturesList = filteredFixturesList.filter(it => it.homeTeam == this.selectedTeam || it.awayTeam == this.selectedTeam);
        }
        if(this.competitionFilter){
          if(this.competitionFilter != 'All'){
            filteredFixturesList = filteredFixturesList.filter((fixture) => fixture.stage == this.competitionFilter);
          }
        }
        filteredFixturesList.sort((a, b) => b.matchday - a.matchday);
        this.filteredFixtures = filteredFixturesList;
      },
      filteredFixturesForStage(stage){
        return this.filteredFixtures.filter((it)=> it.stage == stage);
      },
      formatDate(date) {
        return date.split('-').reverse().map((part, index) => index === 2 ? part.slice(-2) : part).join('/');
      },
      formatDateUS(date){
        return date.replace(/(\d+)\/(\d+)\/(\d{2})(\d{2})/, (_, m, d, __, y) => `${d.padStart(2, '0')}/${m.padStart(2, '0')}/${y}`);
      },
      matchTimeString(item){
        return item.matchResult ? item.homeGoals + ":" + item.homePoints + ' - ' + item.awayGoals + ":" + item.awayPoints : item.time
      },
      compareDates(yyyymmdd, mdy) {
        // Convert YYYY-MM-DD to a Date object
        const [year1, month1, day1] = yyyymmdd.split('-').map(Number);
        const date1 = new Date(year1, month1 - 1, day1); // Months are 0-indexed in JS Date
        
        // Convert M/D/YYYY to a Date object
        const [month2, day2, year2] = mdy.split('/').map(Number);
        const date2 = new Date(year2, month2 - 1, day2); // Months are 0-indexed in JS Date
        
        if (date1 < date2) return -1;
        if (date1 > date2) return 1;
        return 0;  // dates are equal
      },
      customSort(items, sortBy, sortDesc, groupBy, groupDesc) {
        // Custom sorting function to sort the groups in descending order
        if (groupBy.length) {
          const groupedItems = items.sort((a, b) => {
            // Ensure `groupDesc` is considered
            if (groupDesc[0]) {
              return b[groupBy[0]] - a[groupBy[0]];
            }
            return a[groupBy[0]] - b[groupBy[0]];
          });

          // Return sorted items
          return groupedItems;
        }
        return items;
      }
    },
    watch: {
      getFixtures(){
        this.fixtureList();
      }
    }
  }
</script>
<style lang="scss">

@media (max-width: 600px) {
    .v-data-table__wrapper table tbody tr td,
    .v-data-table__wrapper table tbody tr th,
    .v-data-table__wrapper table thead tr td,
    .v-data-table__wrapper table thead tr th,
    .v-data-table__wrapper table tfoot tr td,
    .v-data-table__wrapper table tfoot tr th {
      padding-left: 4px !important;
      padding-right: 2px !important;
    }
}

@media (max-width: 600px) {
  .v-application--is-ltr .v-data-footer__select {
      margin-left: 0 !important; /* Remove the left margin */
  }
}

@media (max-width: 600px) {
  .v-application--is-ltr .v-data-footer__pagination {
      margin-left: 5px !important; /* Remove the left margin */
  }
}

.v-data-table-header th {
  min-width: 70px; /* Set your desired fixed width */
  text-align: left; /* Optional: Set alignment for header text */
}

.header-parent {
    display: flex;
    justify-content: space-between;
}
.positioningElement {
    opacity: 0;
    cursor: auto;
}

.inline-block {
    display: inline-block;
}

.grouper {
    display: flex;
    justify-content: flex-end;
}

.eg-table-bnone {
    tr {
        td {
            border-bottom: none !important;
        }
    }
}
.eg-table-td-py-md {
    tr {
        td {
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
        }
    }
}
.img_border {
    border-style: solid;
    border-width: 1px;
}
</style>
